import React, { useEffect, useState } from "react";
import "./login.css";
import { DocketAPIError, getAPIClient, legalSexFromString } from "../../apiClient";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import db, { Key } from "../../database";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { errorLog, infoLog } from "../../utils/logger";
import DatePicker from "react-datepicker";
import {
  checkBoxValueMap,
  getLocalizedLegalSexChoices,
  getHorizontalRadioSection,
} from "../../utils/formUtils";
import moment from "moment/moment";
import lodash from "lodash";
import { UserAccount } from "../../models/Interfaces";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { useAtom } from "jotai";
import { userAtom } from "../../jotai/atoms";

export function AccountInfo() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();

  const [dob, setDob] = useState<Date | undefined>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | string>(false);
  const [checkboxValue, setCheckboxValue] = useState(getLocalizedLegalSexChoices(t));
  const checkboxHandler = (value: any, index: number) => {
    const newValue = checkBoxValueMap(checkboxValue, index);
    infoLog(`newValue ${JSON.stringify(newValue)}`);
    setCheckboxValue(newValue);
  };

  const [currentUser, setCurrentUser] = useAtom(userAtom);

  useEffect(() => {
    if (currentUser?.dob) {
      setDob(new Date(currentUser.dob));
    }
    if (currentUser?.first_name) {
      setFirstName(currentUser.first_name);
    }
    if (currentUser?.last_name) {
      setLastName(currentUser.last_name);
    }
    if (currentUser?.legal_sex) {
      checkboxHandler(currentUser.legal_sex, 0);
    }
  }, [currentUser]);

  const { handleSubmit } = useForm();

  const searchFormTranslations = {
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
    firstName: t("onboarding.first-name"),
    lastName: t("onboarding.last-name"),
  };

  const ageCheckPassed = () => {
    if (dob && moment().subtract(13, "years").isBefore(moment(dob), "day")) {
      return false;
    }
    return true;
  };

  const onSubmit = async (data: any) => {
    if (!ageCheckPassed()) {
      errorLog({ message: t("settings.dob_error_message") } as Error);
      setShowErrorMessage(`${t("settings.dob_error_message")}`);
      return;
    }
    try {
      let sex = lodash.find(checkboxValue, ["checked", true]);
      let legalSex = sex?.value ?? "U";
      const params = {
        first_name: firstName,
        last_name: lastName,
        legal_sex: legalSex,
        dob: moment(dob).toISOString(),
        language: "en",
      };
      const res = await getAPIClient().updateUser(params);
      // save user info to db
      if (res) {
        await setCurrentUser({
          ...currentUser,
          legal_sex: legalSexFromString(legalSex),
          dob: moment(dob).format("L"),
          first_name: firstName,
          last_name: lastName,
          language: "en",
        });
        navigate("../verifyphone");
      }
    } catch (e: unknown) {
      errorLog(e as Error);
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  const getUserUpdateForm = () => {
    return (
      <div>
        {showErrorMessage && (
          <ErrorModal onCloseModal={() => setShowErrorMessage(false)}>
            {showErrorMessage}
          </ErrorModal>
        )}
        <div className="field mb-3">
          <label className="label form-input-label mb-1">{searchFormTranslations.firstName}</label>
          <div className="control has-background-white position-relative">
            <input
              className="input has-text-dark"
              type="text"
              id={"firstName"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={searchFormTranslations.firstName}
            />
          </div>
        </div>
        <div className="field mb-3">
          <label className="label form-input-label mb-1">{searchFormTranslations.lastName}</label>
          <div className="control has-background-white position-relative">
            <input
              className="input has-text-dark"
              id={"lastName"}
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={searchFormTranslations.lastName}
            />
          </div>
        </div>
        <label className="label form-input-label is-size-6">
          {searchFormTranslations.dateOfBirth}
        </label>
        <DatePicker
          key={"dateOfBirth"}
          selected={dob}
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          customInput={
            <input
              className="input has-dark-text"
              id="dateOfBirth"
              placeholder="MM/DD/YYYY"
            ></input>
          }
          inline={false}
          onChange={(date) => setDob(date!)}
        />
        <br />
        <br />
        {getHorizontalRadioSection(
          checkboxValue,
          searchFormTranslations.legalSex,
          checkboxHandler,
          "form-input-label",
          "gender",
          t
        )}
        <br />
      </div>
    );
  };

  return (
    <>
      <h1 className="has-text-dark is-size-4">{t("onboarding.signup_header")}</h1>
      <div className="separator"></div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="is-flex is-flex-direction-column is-justify-content-space-between is-fullheight"
      >
        {getUserUpdateForm()}

        <div className="is-flex is-flex-direction-column is-align-items-center">
          <button
            className="button docket-button is-fullwidth "
            type="submit"
            disabled={firstName.length < 2 || lastName.length < 2 || !dob || !checkboxValue}
          >
            {t("generic.continue").toUpperCase()}
          </button>
        </div>
      </form>
    </>
  );
}

export default AccountInfo;
