import React from "react";
import { useTranslation } from "react-i18next";
import { DocketSwitch } from "../form/DocketSwitch";
import { IconButton } from "../IconButton/IconButton";

interface SearchFormHeaderProps {
  showChildSearch: boolean;
  showChild: Function;
  deleteSearch: Function;
  showDelete: boolean;
}

export function SearchFormHeader({
  showChildSearch,
  showChild,
  deleteSearch,
  showDelete,
}: SearchFormHeaderProps) {
  //const location = useLocation();
  const { t } = useTranslation();

  // TODO: i18n translation
  return (
    <div className="pt-6 pr-6 pl-6">
      <div className="level-left has-text-dark has-background-white">
        <h2>{t("immunizations.search_start_header")}</h2>
      </div>
      <hr />
      <div className="level">
        <div className="level-left has-text-dark mt-5 ">
          <div className="field is-flex is-align-items-center">
            <label className="form-input-label mr-4">
              {t("immunizations.search_child_search")}
            </label>
            <DocketSwitch
              id="childSearch"
              data-testid="child-search-toggle"
              checked={showChildSearch}
              name={"Add child search?"}
              onChange={(e) => showChild(e.target.checked)}
            />
          </div>
        </div>
        {showDelete && (
          <div className="level-right has-text-dark has-text-right">
            <IconButton
              dataTestId="deleteBtn"
              icon="trash"
              onClick={() => deleteSearch()}
              alt="delete"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchFormHeader;
