import React, { useEffect, useState } from "react";
import { errorLog, infoLog } from "../../utils/logger";
import SearchCard from "../../components/search/SearchCard";
import { useTranslation } from "react-i18next";
import { ImmunizationSearch, SearchStatus } from "../../models/Interfaces";
import { useNavigate } from "react-router";
import { DocketAPIError } from "../../apiClient";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { Modal } from "../../components/modals/Modal";
import "./searchList.css";
import {
  loadableIzSearchesAtom,
  patientsAtom,
  refreshingSearchesOrRecordsAtom,
  selectedSearchAtom,
  userAtom,
} from "../../jotai/atoms";
import { useAtom } from "jotai";
import { Squirrel } from "../../components/spinner/Squirrel";
import { hasScreeningsEnabled } from "../../globals";

/**
 * Calculate when we should next poll for updates.
 *
 * @param timesPolled The number of times we've already polled for data
 * @returns 0 to the maximum poll time in milliseconds, or -1 if we should stop.
 */
export function getNextPollTime(timesPolled: number): number {
  const ONE_SECOND_IN_MS = 1000;
  const minDurationMs = ONE_SECOND_IN_MS;
  const minDurationPolls = 5; // We want to attempt to do some fast polling since in MOST cases the search comes back in < 5 secs
  const maxDurationMs = 60 * 60 * ONE_SECOND_IN_MS; // One poll every hour
  // This is absurdly high on purpose. It's just to prevent idle clients from infinitely driving traffic to us.
  // It takes 12 polls (17 including the min duration polls) to hit 1 hour polling time.
  // The maximum time a message can exist in the backend SQS queue is 14 days, or 336 hours.
  // 17 + 336 = 353.
  const maxPolls = 353;

  // We poll the backend as long as we're waiting on at least 1 search or record request from the backend.
  if (timesPolled < maxPolls) {
    if (timesPolled <= minDurationPolls) {
      // We're within our 'fast polling' window so throw out the previous calculation.
      return minDurationMs;
    }

    // We want an exponential decay with full jitter.
    // Canonical article from mbrooker: https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
    const expectedNextRequestTime = Math.min(
      maxDurationMs,
      minDurationMs * Math.pow(2, timesPolled - minDurationPolls)
    );
    // Add in a full jitter
    return Math.round(Math.random() * expectedNextRequestTime);
  }
  return -1;
}

export function SearchList() {
  const navigate = useNavigate();
  //  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null as unknown as ImmunizationSearch);
  const [showErrorModal, setShowErrorModal] = useState<string | boolean>();
  const [timesPolled, setTimesPolled] = useState(1);
  // searches selector to update UI
  const [curSearch, setCurSearch] = useAtom(selectedSearchAtom);
  const [allSearches] = useAtom(loadableIzSearchesAtom);
  const [refreshInProgress] = useAtom(refreshingSearchesOrRecordsAtom);
  const [user] = useAtom(userAtom);
  const [allPatients] = useAtom(patientsAtom);
  const timeoutFunc = React.useRef<NodeJS.Timeout | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (allSearches.state === "hasData") {
      setIsInitialLoad(false);
    }
  }, [allSearches.state]);

  function getSearchHeader() {
    return (
      <div className="level pt-6 has-background-transparent ml-5 mr-5">
        <div className="level-left ml-4 has-text-dark has-text-left">
          {allSearches.state === "hasData" && (
            <h2>{`${t("immunizations.search_header_pt1")} ${allSearches.data.length} ${t(
              "immunizations.search_header_pt2"
            )}`}</h2>
          )}
        </div>
        <div className="level-right has-text-dark has-text-right">
          <button
            data-testid="newSearchBtn"
            className="button docket-button pl-5 pr-5 pt-2 pb-2 is-flex-mobile search-button mr-5"
            onClick={async () => {
              await setCurSearch(null);
              navigate("newsearch");
            }}
          >
            {t("immunizations.search_start_new_search")}
          </button>
        </div>
      </div>
    );
  }

  const selectItemHandler = async (searchObj: ImmunizationSearch) => {
    setSelectedSearch(searchObj);
    await setCurSearch(searchObj);
    if (searchObj.status === SearchStatus.inQueue) {
      // await pullToRefresh()
    } else if (
      searchObj.status === SearchStatus.noRecords ||
      searchObj.status === SearchStatus.multiMatch
    ) {
      setShowNoMatchModal(true);
    } else if (
      searchObj.status === SearchStatus.partialMatchAltContacts &&
      !searchObj.dateVerified
    ) {
      navigate("altcontacts", { state: { fromSearchList: true } });
    } else if (
      // results other than 0, like ones above, either
      // need to be result 2 or need dateVerified
      searchObj.status !== SearchStatus.noMatch &&
      searchObj.status !== SearchStatus.multiMatch &&
      searchObj.status !== SearchStatus.basicMatchNoContacts &&
      searchObj.dateVerified
    ) {
      setShowMatchModal(true);
    } else {
      setShowNoMatchModal(true);
    }
  };

  const getNoMatchModal = () => {
    let title = "";
    let message: string = t("immunizations.no_match_multi_match_message");
    if (selectedSearch?.status === SearchStatus.basicMatchNoContacts) {
      title = t("immunizations.search_action_required");
      message = t("immunizations.search_action_required_message");
    } else if (selectedSearch?.status === SearchStatus.noMatch) {
      title = t("immunizations.no_match_alert_title");
    } else {
      title = t("immunizations.no_match_multi_match_alert_title");
    }

    return (
      showNoMatchModal && (
        <Modal
          hasClose={true}
          title={title}
          onCloseModal={() => setShowNoMatchModal(false)}
          actionButtons={
            <>
              <button
                className="button docket-button secondary pl-4 pr-4"
                aria-label="Review search"
                onClick={() => navFromNoMatchModal(true)}
              >
                {t("immunizations.no_match_multi_match_review_button")}
              </button>
              <button
                className="button docket-button pl-4 pr-4"
                aria-label="More info"
                onClick={() => navFromNoMatchModal(false)}
              >
                {t("immunizations.no_match_multi_match_no_match_button")}
              </button>
            </>
          }
        >
          <p>{message}</p>
        </Modal>
      )
    );
  };

  const navFromNoMatchModal = (review: boolean) => {
    if (review) {
      navigate("newsearch");
    } else {
      navigate("results", { state: { isScenarioB: false } });
    }
    setShowNoMatchModal(false);
  };

  const getSearchNavModal = () => {
    return (
      showMatchModal && (
        <Modal
          hasClose={true}
          title={t("immunizations.records_found_alert_header")}
          onCloseModal={() => setShowMatchModal(false)}
          actionButtons={
            <>
              <button
                className="button docket-button pl-4 pr-4"
                aria-label="View immunizations"
                onClick={() => navFromMatchModal("records")}
              >
                {t("immunizations.records_found_view_records")}
              </button>

              {hasScreeningsEnabled(user?.email || "") && (
                <button
                  className="button docket-button pl-4 pr-4"
                  aria-label="View screenings"
                  onClick={() => navFromMatchModal("screenings")}
                >
                  {t("immunizations.records_found_view_screenings")}
                </button>
              )}
              <button
                className="button docket-button secondary pl-4 pr-4"
                aria-label="Edit search"
                onClick={() => navFromMatchModal("search")}
              >
                {t("immunizations.records_found_edit_search")}
              </button>
            </>
          }
        >
          <p>{t("immunizations.records_found_alert_message")}</p>
        </Modal>
      )
    );
  };

  const navFromMatchModal = (nextPage: "records" | "screenings" | "search") => {
    const patient = allPatients.find((p) => p.searchUid === selectedSearch?.uid);
    //infoLog(`patient index ${allPatients.indexOf(patient!)}`);
    if (patient && nextPage === "records") {
      if (patient.records && patient.records.length > 0) {
        navigate("/home/records#" + allPatients.indexOf(patient));
      } else {
        navigate("/home/records");
      }
    } else if (patient && nextPage === "screenings") {
      if (patient.records && patient.records.length > 0) {
        navigate("/home/records/screening#" + allPatients.indexOf(patient));
      } else {
        navigate("/home/records/screening");
      }
    } else {
      navigate("newsearch");
    }
    setShowMatchModal(false);
  };

  const renderSearchContent = () => {
    if (allSearches.state === "hasError") {
      setShowErrorModal(t("immunizations.search_records_error"));
      return <></>;
    }

    if (allSearches.state === "loading" || isInitialLoad) {
      return <Squirrel />;
    }

    if (!isInitialLoad && allSearches.state === "hasData" && allSearches.data.length === 0) {
      return (
        <div className={`fade-in has-text-centered ${!isInitialLoad ? "visible" : ""}`}>
          <br />
          <h2 className="is-size-5 has-text-weight-bold has-text-black">
            {t("immunizations.search_records_message")}
          </h2>
          <br />
          <br />
          <button
            className="button docket-button pl-4 pr-4"
            onClick={async () => {
              await setCurSearch(null);
              navigate("newsearch");
            }}
          >
            {t("immunizations.immunizations_add_records_button")}
          </button>
        </div>
      );
    }

    return (
      <ul>
        {allSearches.data.map((search, index) => (
          <li className="mb-4" key={index}>
            <SearchCard
              search={search}
              index={index}
              onSelect={() => {
                selectItemHandler(search);
              }}
            />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {getSearchNavModal()}
      {getNoMatchModal()}
      {getSearchHeader()}
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>
          <p>{showErrorModal}</p>
        </ErrorModal>
      )}
      <div className="section">{renderSearchContent()}</div>
    </>
  );
}

export default SearchList;
