import React, { useEffect, useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";
import "./login.css";
import { Link } from "react-router-dom";
import { DocketAPIError, getAPIClient } from "../../apiClient";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import { errorLog } from "../../utils/logger";
import {
  GoogleSignup,
  AppleSignUp,
  AppleOauthResponse,
  UserAccount,
} from "../../models/Interfaces";
import { useTranslation } from "react-i18next";
import { Login as LoginInput } from "../../models/Interfaces";
import AppleLogin from "react-apple-login";
import {
  getAuth,
  connectAuthEmulator,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailLink,
  isSignInWithEmailLink,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { REDIRECT_URI, STAGE, STAY_LOGGED_IN_PW, WHITELABEL_KEY } from "../../globals";
import { LayoutCard } from "../../components/onboarding/layoutCard/LayoutCard";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { Modal } from "../../components/modals/Modal";
import { useBranding } from "../../branding/useBranding";
import { appSettingsAtom, writeOnlyUserAtom } from "../../jotai/atoms";
import { useAtom } from "jotai";
import db from "../../database";

const app = initializeApp(firebaseConfig());
const provider = new GoogleAuthProvider();

export function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const branding = useBranding();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loadApple, setLoadApple] = useState(false);
  const [isLoginWithState, setIsLoginWithState] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [openEmailPrompt, setOpenEmailPrompt] = useState(false);
  const [promptedEmail, setPromptedEmail] = useState("");
  const [_nullUser, setUser] = useAtom(writeOnlyUserAtom);
  const [appSettings, setAppSettings] = useAtom(appSettingsAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const errorMessage = useRef(t("onboarding.login_error"));

  const auth = getAuth(app);
  if (STAGE === "development" && !appSettings.firebaseEmulatorConnected) {
    connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });
    setAppSettings({
      ...appSettings,
      firebaseEmulatorConnected: true,
    });
  }

  /**
   * Handles the two operations we need to perform with the user object upon login. Namely that is
   * - Setting up the database's crypto key based on the user so that we can start storing data.
   * - Saving the user in the Jotai atom
   */
  const updateCryptoKeyAndUser = async (user: UserAccount) => {
    await db().updateCryptoKey(user, STAY_LOGGED_IN_PW);
    await setUser(user);
  };

  const loginWithPromptedEmail = () => {
    setOpenEmailPrompt(false);
    setLoggingIn(true);
    signInWithEmailLink(auth, promptedEmail, window.location.href)
      .then((result) => {
        result.user.getIdToken().then((idToken) => {
          getAPIClient()
            .loginWithFirebase(idToken)
            .then(async (loginResult) => {
              if (!loginResult || !loginResult.tokens) {
                // This really shouldn't happen
                const err = new Error("Did not get a log in result or token");
                Bugsnag.notify(err);
                throw err;
              }

              await setAppSettings({
                ...appSettings,
                email: loginResult.email || "",
                authorized: true,
                signedInWithFirebase: true,
              });

              //              dispatch(setPassword(STAY_LOGGED_IN_PW));
              //              await db().setItem(Key.UserAccount, userWithPassword);

              // This does magic to ensure the user is actually stored locally
              await updateCryptoKeyAndUser(loginResult);

              // First param is the user ID, which we don't expose
              Bugsnag.setUser("", loginResult.email);
              // Check if user doesn't have info fill out
              if (
                !loginResult.dob ||
                !loginResult.legal_sex ||
                !loginResult.first_name ||
                !loginResult.last_name
              ) {
                navigate("/signup/accountinfo");
              } else {
                navigate("/home/search");
              }
            })
            .catch((e: any) => {
              if (e instanceof DocketAPIError) {
                errorMessage.current = e.message;
              } else {
                errorLog(e);
              }
              setShowErrorModal(true);
            });
        });
      })
      .catch((error) => {
        errorMessage.current = t("onboarding.login-error");
        setShowErrorModal(true);
      })
      .finally(() => {
        window.localStorage.removeItem("emailForSignIn");
        setLoggingIn(false);
      });
  };

  useEffect(() => {
    // Check for 'code' query param in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const stateParameter = urlParams.get("stateIdentifier");
    const code = urlParams.get("code");

    /* Magical links */
    const auth = getAuth(app);
    if (STAGE === "development" && !appSettings.firebaseEmulatorConnected) {
      connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });
      setAppSettings({
        ...appSettings,
        firebaseEmulatorConnected: true,
      });
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      setLoggingIn(true);
      const email = window.localStorage.getItem("emailForSignIn");

      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            result.user.getIdToken().then((idToken) => {
              getAPIClient()
                .loginWithFirebase(idToken)
                .then(async (loginResult) => {
                  if (!loginResult || !loginResult.tokens) {
                    // This really shouldn't happen
                    const err = new Error("Did not get a log in result or token");
                    Bugsnag.notify(err);
                    throw err;
                  }

                  await updateCryptoKeyAndUser(loginResult);
                  await setAppSettings({
                    ...appSettings,
                    email: loginResult.email || "",
                    authorized: true,
                  });

                  //dispatch(setPassword(STAY_LOGGED_IN_PW));
                  //await db().setItem(Key.UserAccount, userWithPassword);
                  // First param is the user ID, which we don't expose
                  Bugsnag.setUser("", loginResult.email);
                  // Check if user doesn't have info fill out
                  if (
                    !loginResult.dob ||
                    !loginResult.legal_sex ||
                    !loginResult.first_name ||
                    !loginResult.last_name
                  ) {
                    navigate("/signup/accountinfo");
                  } else {
                    navigate("/home/search");
                  }
                })
                .catch((e: any) => {
                  if (e instanceof DocketAPIError) {
                    errorMessage.current = e.message;
                  } else {
                    errorLog(e);
                  }
                  setShowErrorModal(true);
                });
            });
          })
          .catch((error) => {
            errorMessage.current = t("onboarding.login-error");
            setShowErrorModal(true);
          })
          .finally(() => {
            window.localStorage.removeItem("emailForSignIn");
            setLoggingIn(false);
          });
      } else {
        setLoggingIn(false);
        setOpenEmailPrompt(true);
      }
    }

    /* State OIDC */
    if (code && stateParameter) {
      setLoggingIn(true);
      getAPIClient()
        .loginWithAuthCode(code, stateParameter)
        .then(async (loginResult) => {
          if (!loginResult || !loginResult.tokens) {
            // This really shouldn't happen
            const err = new Error("Did not get a log in result or token");
            Bugsnag.notify(err);
            throw err;
          }

          await updateCryptoKeyAndUser(loginResult);
          await setAppSettings({
            ...appSettings,
            email: loginResult.email || "",
            authorized: true,
          });
          // First param is the user ID, which we don't expose
          Bugsnag.setUser("", loginResult.email);
          // Check if user doesn't have info fill out
          if (
            !loginResult.dob ||
            !loginResult.legal_sex ||
            !loginResult.first_name ||
            !loginResult.last_name
          ) {
            navigate("/signup/accountinfo");
          } else {
            navigate("/home/search");
          }
          setLoggingIn(false);
        })
        .catch((e: any) => {
          if (e instanceof DocketAPIError) {
            errorMessage.current = e.message;
          } else {
            errorLog(e);
          }
          setShowErrorModal(true);
          setLoggingIn(false);
        });
    }

    if ((window as any).AppleID) {
      (window as any).AppleID.auth.init({
        clientId: "com.foxhallwythe.docket.mobile.apple.signin",
        redirectURI: REDIRECT_URI,
        scope: "email name",
        state: "",
        nonce: "",
        usePopup: true,
      });
    }
  }, []);

  useEffect(() => {
    if (appSettings.authorized) {
      navigate("/home/search", { replace: true });
    }
  }, [appSettings]);

  // hack / workaround to check if apple id isset
  // but essentially theres a bug with the react-apple-login  due to the fact that apple recently updated a few things.
  // I actually found this workaround in the repo library https://github.com/patelmayankce/react-apple-login/issues/41
  useEffect(() => {
    const interval = setInterval(() => {
      if (!(window as any)["AppleID"]) {
        return;
      } else {
        setLoadApple(true);
        clearInterval(interval);
      }
    }, 500);

    return () => {};
  }, [window, setLoadApple]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginInput>();

  const onMagicLink: SubmitHandler<LoginInput> = async (data) => {
    try {
      setIsSubmitting(true);
      window.localStorage.setItem("emailForSignIn", data.email);
      await getAPIClient().sendSignInLink(data.email);
      setMagicLinkSent(true);
    } catch (e: any) {
      if (e && e instanceof DocketAPIError) {
        errorMessage.current = e.message;
        if ((e.cause as any)?.response?.status === 429) {
          errorMessage.current = t("onboarding.login-error-429");
        }
        errorLog(e);
      } else {
        errorLog(e);
      }
      setShowErrorModal(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);

    if (!result) {
      errorMessage.current = t("onboarding.login-error-google");
      setShowErrorModal(true);
      return;
    }

    const { user: googleuser } = result;
    const credential = GoogleAuthProvider.credentialFromResult(result);

    const googleSignup: GoogleSignup = {
      email: googleuser?.email || "",
      first_name: googleuser?.displayName?.split(" ")[0] || "",
      last_name: googleuser?.displayName?.split(" ")[1] || "",
      token: credential?.idToken || "",
      google_id: googleuser?.uid,
      offset: "", //NSTimeZone.system.secondsFromGMT() equivalent
    };

    try {
      const loginResult = await getAPIClient().createUser({ user: googleSignup });

      if (!loginResult || !loginResult.tokens?.access) {
        // This really shouldn't happen
        const err = new Error("Did not get a login result or token");
        Bugsnag.notify(err);
        throw err;
      }

      //infoLog(`loginResult ${JSON.stringify(loginResult)}`);

      await updateCryptoKeyAndUser(loginResult);
      await setAppSettings({
        ...appSettings,
        email: loginResult.email || "",
        authorized: true,
      });

      // First param is the user ID, which we don't expose
      Bugsnag.setUser("", loginResult.email);
      if (
        !loginResult.dob ||
        !loginResult.legal_sex ||
        !loginResult.first_name ||
        !loginResult.last_name
      ) {
        navigate("/signup/accountinfo");
      } else {
        navigate("/home/search");
      }
    } catch (e: any) {
      if (e instanceof DocketAPIError) {
        errorMessage.current = e.message;
      } else {
        errorLog(e);
      }
      setShowErrorModal(true);
    }
  };

  const signInWithApple = async (response: AppleOauthResponse) => {
    if (response.error && response.error.message) {
      errorMessage.current = response?.error?.message;
      setShowErrorModal(true);
      return;
    }

    if (response.authorization && response.authorization.id_token) {
      const base64Url = response?.authorization?.id_token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const appleUser = JSON.parse(jsonPayload);

      const appleSignup: AppleSignUp = {
        code: response?.authorization?.code || "",
        token: response?.authorization?.id_token || "",
        email: appleUser?.email ?? "noEmail@apple.com",
        first_name: appleUser.name?.first_name ?? "firstName",
        last_name: appleUser?.name?.last_name ?? "lastName",
      };

      try {
        const loginResult = await getAPIClient().createUser({ user: appleSignup });

        if (!loginResult || !loginResult.tokens?.access) {
          // This really shouldn't happen
          const err = new Error("Did not get a login result or token");
          Bugsnag.notify(err);
          throw err;
        }

        // oauth users don't have a password....

        await updateCryptoKeyAndUser(loginResult);
        await setAppSettings({
          ...appSettings,
          email: loginResult.email || "",
          authorized: true,
        });
        // First param is the user ID, which we don't expose
        Bugsnag.setUser("", loginResult.email);
        if (
          !loginResult.dob ||
          !loginResult.legal_sex ||
          !loginResult.first_name ||
          !loginResult.last_name
        ) {
          navigate("/signup/accountinfo");
        } else {
          navigate("/home/search");
        }
      } catch (e: any) {
        if (e instanceof DocketAPIError) {
          errorMessage.current = e.message;
        } else {
          errorLog(e);
        }
        setShowErrorModal(true);
      }
    }
  };

  const loginWithState = async (state: string) => {
    await getAPIClient().initiateOidcAuth(state);
  };

  return (
    <section className="section hero height-fitcontent">
      {magicLinkSent && (
        <div className="pb-6">
          <Link
            to="/"
            className={`is-${branding.backlinkColor}-text`}
            onClick={() => setMagicLinkSent(false)}
          >
            {t("onboarding.signup-back-button")}
          </Link>
        </div>
      )}
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>
          <p>{errorMessage.current}</p>
        </ErrorModal>
      )}
      <LayoutCard>
        <div className="columns">
          <div className="column is-fullWidth p-6">
            <div className="container has-text-centered mb-4">
              <img src={branding.logo} alt="Docket logo" />
            </div>
            <div className="separator"></div>

            {isLoginWithState ? (
              <div className="is-fullWidth is-flex is-flex-direction-column is-justify-content-space-between is-flex-1">
                <a
                  onClick={() => setIsLoginWithState(false)}
                  role="button"
                  className="docket-link mb-2"
                >
                  {t("generic.back")}
                </a>
                <div className="has-text-dark mt-2 is-size-6 mb-2 mt-2">
                  {t("onboarding.state-disclaimer")}
                </div>
                <button
                  data-testid="alaska-login-button"
                  onClick={() => loginWithState("ak")}
                  type="button"
                  className="button is-large mt-2 is-fullwidth"
                >
                  <img src={"assets/images/myalaska.png"} className="state-id-img" />
                </button>
                <button
                  data-testid="utah-login-button"
                  onClick={() => loginWithState("ut")}
                  type="button"
                  className="button is-large mt-2 is-fullwidth"
                >
                  <img src={"assets/images/utahid-logo.svg"} className="state-id-img" />
                </button>
                <div className="mt-6">
                  <div className="has-text-dark mt-2 is-size-7 mb-2 mt-6">
                    <b>{t("generic.warning")}:</b> {t("onboarding.state-persist-message")}
                  </div>
                </div>
                <p className="has-text-dark is-size-7">{t("onboarding.inactivity-message")}</p>
              </div>
            ) : (
              <>
                {magicLinkSent && (
                  <div className="is-fullWidth">
                    <div className="container is-fullWidth has-text-centered">
                      <h4 className="has-text-dark mt-6 mb-4 is-size-4">
                        <b>{t("onboarding.sent-signin-link")}</b>
                      </h4>
                      <p className="has-text-dark mt-4 mb-4 email-wrap">
                        {window.localStorage.getItem("emailForSignIn")}
                      </p>
                      <p className="has-text-dark">{t("onboarding.click-signin-link")}</p>
                    </div>
                  </div>
                )}
                {loggingIn && (
                  <div className="is-fullWidth mt-6">
                    <div className="container is-fullWidth has-text-centered">
                      <h4 className="has-text-dark mt-6 mb-4 is-size-4">
                        <b>{t("onboarding.signing-in-title")}</b>
                      </h4>
                      <h4 className="has-text-dark mt-4 mb-4 is-size-4">
                        {t("onboarding.signing-in-message")}
                      </h4>
                    </div>
                  </div>
                )}
                {!magicLinkSent && !loggingIn && (
                  <>
                    <div className="is-fullWidth">
                      <div className="container is-fullWidth">
                        <form onSubmit={handleSubmit(onMagicLink)}>
                          <div className="field mb-4 mt-4">
                            <label className="label form-input-label">
                              {t("onboarding.email-address")}
                            </label>

                            <div className="control-docket">
                              <input
                                type="email"
                                placeholder={t("onboarding.email")}
                                className="input has-text-dark"
                                aria-label={t("onboarding.email")}
                                defaultValue=""
                                {...register("email", {
                                  required: t("onboarding.email-required"),
                                })}
                              />
                              {errors["email"] && (
                                <div className="validation-error pl-2 mt-1">
                                  {<span>{errors["email"] && errors["email"].message}</span>}
                                </div>
                              )}
                            </div>
                          </div>
                          <p className="has-text-dark is-size-7">
                            {t("onboarding.inactivity-message")}
                          </p>
                          <div className="field mt-4">
                            <div className="control-docket">
                              <button
                                className="button is-fullwidth docket-button"
                                type="submit"
                                disabled={isSubmitting || errors.email !== undefined}
                              >
                                {t("onboarding.signup_or_login")}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <br />
                    <div className="columns is-mobile">
                      <div className="column">
                        <hr style={{ backgroundColor: "white", height: "1px" }} />
                      </div>
                      <div className="column is-narrow">
                        <div className="has-text-dark">{t("onboarding.or-login-with")}</div>
                      </div>
                      <div className="column">
                        <hr style={{ backgroundColor: "white", height: "1px" }} />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column is-fullwidth">
                        <button
                          data-testid="Sign-in-Google"
                          onClick={signInWithGoogle}
                          type="button"
                          className="button is-large is-fullwidth"
                        >
                          <figure className="image" style={{ width: "40px", marginRight: "10px" }}>
                            <img src="/assets/images/google_button.png" alt="Sign in with Google" />
                          </figure>
                          <span>Google</span>
                        </button>
                      </div>
                      <div className="column is-half">
                        {loadApple && (
                          <AppleLogin
                            clientId="com.foxhallwythe.docket.mobile.apple.signin"
                            redirectURI={REDIRECT_URI}
                            usePopup={true}
                            callback={signInWithApple} // Catch the response
                            scope={"email name"}
                            responseMode={"query"}
                            responseType={"code id_token"}
                            render={(renderProps) => (
                              //Custom Apple Sign in Button
                              <button
                                data-testid="Sign-in-Apple"
                                type="button"
                                onClick={renderProps.onClick}
                                className="button is-large is-fullwidth"
                              >
                                <figure
                                  className="image"
                                  style={{ width: "40px", marginRight: "10px" }}
                                >
                                  <img
                                    src="/assets/images/apple_button.png"
                                    alt="Sign in with Apple"
                                  />
                                </figure>
                                Apple
                              </button>
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="columns mb-0">
                      <div className="column is-fullwidth">
                        <button
                          data-testid="state-issued-button"
                          onClick={() => setIsLoginWithState(true)}
                          type="button"
                          className="button is-large is-fullwidth state-button"
                        >
                          <span className="is-size-5 is-size-7-mobile is-hidden-mobile">
                            {t("onboarding.state-login-option")}
                          </span>
                          <span className="is-size-5 is-hidden-tablet">
                            {t("onboarding.state-login-option")}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="has-text-dark is-size-7 mb-4">
                      {t("onboarding.allow-popups")}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="column is-flex-1 is-hidden-mobile signup-image">
            <img src={branding.loginBackgroundImage} />
            <div className="login-badge">{t("onboarding.immunization_slogan")}</div>
          </div>
        </div>
      </LayoutCard>
      {openEmailPrompt && (
        <Modal
          onCloseModal={() => setOpenEmailPrompt(false)}
          hasClose={true}
          title={t("onboarding.enter_email")}
          onConfirm={loginWithPromptedEmail}
          confirm={t("onboarding.login")}
        >
          <div className="field mb-4 mt-4">
            <p className="mb-4">{t("onboarding.login-reenter-email")}</p>
            <label htmlFor="promptedEmail" className="label form-input-label">
              Email
            </label>
            <div className="control-docket">
              <input
                id="promptedEmail"
                className="input has-text-dark"
                placeholder={t("onboarding.email")}
                value={promptedEmail}
                onChange={(e) => setPromptedEmail(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      )}
      {WHITELABEL_KEY === "nj" && (
        <footer className="footer mt-5">
          <div className="footer-content is-flex is-flex-direction-column is-align-items-center is-fullwidth">
            <p className="has-text-dark">
              <a
                className="has-dark-text link mr-2 ml-2"
                href="https://www.nj.gov/health/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("onboarding.nj_link")}
              </a>{" "}
              |{" "}
              <a
                className="has-dark-text link link mr-2 ml-2"
                href="https://dockethealth.com/terms_of_use"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("onboarding.terms_link")}
              </a>{" "}
              |{" "}
              <a
                className="has-dark-text link link mr-2 ml-2"
                href="https://dockethealth.com/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("onboarding.privacy_link")}
              </a>
            </p>
          </div>
        </footer>
      )}
    </section>
  );
}

export default Login;
